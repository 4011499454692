"use client";

import CustomError from "@/components/CustomError/CustomError";

const ErrorPage = () => (
    <CustomError
        statusCode={404}
        title="Page not found"
        description="The page you are looking for does not exist"
    />
);

export default ErrorPage;
