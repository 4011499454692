"use client";

import type { UiLanguage } from "@/redux/slices/ui/types";
import { useTranslation } from "@/translation/i18n";
import { type HTMLAttributes, createElement } from "react";

type TitleProps = {
    type: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    content?: keyof UiLanguage;
} & HTMLAttributes<HTMLHeadingElement>;

const Title: React.FC<TitleProps> = ({ type, content, children, ...props }) => {
    const { translate } = useTranslation();

    const heading = createElement(type, props, content ? translate(content) : children);

    return heading;
};

export default Title;
