"use client";

import type { UiLanguage } from "@/redux/slices/ui/types";
import { useTranslation } from "@/translation/i18n";
import type { HTMLAttributes } from "react";

type ParagraphProps = {
    content?: keyof UiLanguage;
} & HTMLAttributes<HTMLParagraphElement>;

const Paragraph: React.FC<ParagraphProps> = ({ content, children, ...props }) => {
    const { translate } = useTranslation();

    return <p {...props}>{content ? translate(content) : children}</p>;
};

export default Paragraph;
