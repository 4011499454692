"use client";

import type { Params } from "@/lib/routes/types";
import { useTranslation } from "@/translation/i18n";
import Link from "next/link";
import { useParams } from "next/navigation";
import Paragraph from "../Paragraph/Paragraph";
import Title from "../Title/Title";

type CustomErrorProps = {
    statusCode: number;
    title: string;
    description: string;
};

const CustomError: React.FC<CustomErrorProps> = ({ statusCode, title, description }) => {
    const { lang } = useParams<Params>();
    const { translate } = useTranslation();

    return (
        <div className="w-full h-[calc(100vh-320px)] flex flex-col justify-center items-center gap-4">
            <Title
                type="h1"
                className="text-[8rem] text-error font-bold"
            >
                {statusCode}
            </Title>
            <div className="flex flex-col justify-center items-center gap-2">
                <Title
                    type="h2"
                    className="text-4xl text-white font-bold"
                >
                    {title}
                </Title>
                <Paragraph className="text-lg text-fontSecondary font-semibold">{description}</Paragraph>
            </div>
            <Link
                className="py-2 px-7 rounded-lg text-white text-lg font-semibold bg-[#5865f2] hover:bg-[#4752c5]"
                href={`/${lang}`}
            >
                {translate("header.item.home")}
            </Link>
        </div>
    );
};

export default CustomError;
